<template>
  <FocusTrap>
    <div  id="cardwindow" class="card">

      <div class="card-header header-elements-inline" style="background-color: whitesmoke;padding: 10px;" >
        <h5 v-if="voucher.id == 0" class="card-title">Stock Adjustment Creation</h5>
        <h5 v-if="voucher.id > 0" class="card-title"> Doc No: {{voucher.doc_no}}  Stock Adjustment Updation</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
            <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="row">

          <div class="col-md-3">
            <div class="form-group form-group-material">

            </div>
          </div>
          <div class="col-md-7">
          </div>

          <div class="col-md-2">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Date</label>
              <input id="txtDate" type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date" autofocus>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="table-responsive">
            <table id="mytable" class="table table-bordered table-columned">
              <thead style="background-color: lightgrey">
              <tr>
                <th style="width:50px;">S.No</th>
                <th style="padding: 5px;">Nozzle</th>
                <th style="padding: 5px;">Tank</th>
                <th style="padding: 5px;">Product</th>
                <th style="width:150px; text-align: right;">Add(Ltrs)</th>
                <th style="width:150px; text-align: right;">Less(Ltrs)</th>
              </tr>

              </thead>
              <tbody >
              <tr v-for="(detail,index) in voucher.list" >
                <td style="text-align: center;padding: 0px;">
                  {{index + 1}}
                </td>
                <td style="padding: 0px;">
                  {{detail.nozzle.name}}
                </td>
                <td style="padding: 0px;">
                  /{{detail.nozzle.tank.name}}
                </td>
                <td style="padding: 0px;">
                  {{detail.nozzle.tank.item.name}}
                </td>
                <td style="padding: 0px;">
                  <input type="number" class="form-control text-right" min="0"  step="any" placeholder="Add Qty" v-if="detail" v-model="detail.debit"/>
                </td>
                <td style="padding: 0px;">
                  <input type="number" class="form-control text-right" min="0"  step="any" placeholder="Less Qty" v-if="detail" v-model="detail.credit"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row" style="padding-top: 5px;">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Narration</label>
              <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
            </div>
          </div>

          <div class="col-md-6">
          </div>

          <div class="col-md-3 text-right">
            <SaveButton title="Save" @handle_save_action="saveVoucher" style="margin: 1em;"></SaveButton>
            <CloseButton title="Clear" @handle_close_action="clear" ></CloseButton>
          </div>

        </div>
      </div>

    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import CloseButton from '../../../components/core/buttons/CloseButton'
import SaveButton from '../../../components/core/buttons/SaveButton'

export default {
  name: 'MeterreadingForm',
  components: {
    SaveButton,
    CloseButton
  },
  store,
  data () {
    return {
      readonly: false,
      nozzles:[],
      rowCreditTotal: 0.0,
      rowDebitTotal: 0.0,
      voucher: JSON.parse('{"id":0,"status":"Active","locked":false,"type":0,"brn_id":0,"finyear":0,"doc_date":"0001-01-01","doc_no":"","remarks":"","maker":"","list":[]}')
    }
  },
  props: {
    myvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"Active","locked":false,"type":0,"brn_id":0,"finyear":0,"doc_date":"0001-01-01","doc_no":"","remarks":"","maker":"","list":[]}')
    }
  },
  beforeMount () {
    this.voucher = this.myvoucher;
    // this.sumrowCreditTotal();
  },
  created () {
    this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
  },
  mounted () {
    const self = this;

    self.loadData();

    if (self.$data.voucher.doc_date == '0001-01-01') {
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    }

    $('#txtDate').focus();

  },
  methods: {
    indianFormat (val) {
      return parseFloat(val).toFixed(2);
    },
    closeThis () {
      this.$emit('stockadjust_window_closed');
      if (this.$data.voucher.id == 0) {
        this.$router.push('/');
      }else{
        this.voucher = JSON.parse('{"id":0,"status":"Active","locked":false,"type":0,"brn_id":0,"finyear":0,"doc_date":"0001-01-01","doc_no":"","remarks":"","maker":"","list":[]}');
      }
    },
    addRow () {
      const self = this;
      self.$data.voucher.list.push(JSON.parse('{"idx":0,"id":0,"name":"","nozzle_id":0,"tank_id":0,"brn_id":0,"cmp_id":0,"openreading":0,"currentreading":0,"tank":{"id":0,"name":"","brn_id":0,"cmp_id":0,"item_id":0,"dipchart_id":0,"capacity":0,"openstock":0,"opendip":0,"item":{"id":0,"name":""},"branch":{"id":0,"name":""}},"credit":0,"debit":0}'));

      setTimeout( ()=> {
        $('tbody>tr').last().find('td:eq(1) select').focus();
      }, 99);
    },
    sumrowCreditTotal () {
      const self = this;

      self.$data.rowCreditTotal = 0.0;
      self.$data.rowDebitTotal = 0.0;
      self.$data.voucher.list.forEach(function (detail) {
        self.$data.rowCreditTotal += parseFloat(detail.credit);
        self.$data.rowDebitTotal  += parseFloat(detail.debit);
      });

    },
    clear () {
      const self = this;
      self.$data.voucher = JSON.parse('{"id":0,"status":"Active","locked":false,"type":0,"brn_id":0,"finyear":0,"doc_date":"0001-01-01","doc_no":"","remarks":"","maker":"","list":[]}');

      self.$data.voucher.list = [];
      self.$data.rowCreditTotal = 0.0;
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    },
    loadData () {
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      // fetch the nozzles
      self.$data.nozzles = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/nozzles/active`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if(_.isArray(resp.data)){
            self.$data.nozzles = resp.data;

            if( self.$data.voucher.id == 0) {
              self.$data.nozzles.forEach((nozzle) => {
                let detail = JSON.parse('{"idx":0,"id":0,"name":"","nozzle_id":0,"tank_id":0,"brn_id":0,"cmp_id":0,"openreading":0,"currentreading":0,"tank":{"id":0,"name":"","brn_id":0,"cmp_id":0,"item_id":0,"dipchart_id":0,"capacity":0,"openstock":0,"opendip":0,"item":{"id":0,"name":""},"branch":{"id":0,"name":""}},"credit":0,"debit":0}');
                detail.nozzle_id = nozzle.id;
                detail.tank_id = nozzle.tank.id;
                detail.item_id = nozzle.tank.item.id;
                detail.nozzle = nozzle;

                self.$data.voucher.list.push(detail);
              });
            }

          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oops', text: err.toString(), type: 'error' });
      });

    },
    removeRow (index) {
      if (index > -1) {
        this.$data.voucher.list.splice(index, 1);
        this.sumrowCreditTotal();
      }
    },
    saveVoucher () {
      const self = this;
      try {
        let myvoucher = JSON.parse(JSON.stringify(self.$data.voucher));
        myvoucher.finyear = self.$store.state.user.finyear;
        myvoucher.brn_id = parseInt(self.$store.state.user.branch.id);
        myvoucher.doc_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');
        myvoucher.ref_date = moment(myvoucher.ref_date).format('YYYY-MM-DD');

        myvoucher.list.forEach(function (detail) {
          detail.nozzle_id = parseInt(detail.nozzle_id);
          detail.item_id = parseInt(detail.item_id);
          detail.debit = parseFloat(detail.debit);
          detail.credit = parseFloat(detail.credit);
        });

        console.log(JSON.stringify(myvoucher));

        const requestOptions = {
          method: ((myvoucher.id == 0) ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        }

        $('#cardwindow').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        $('#txtDate').focus();
        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/stockadjustment`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            self.$emit('stockadjust_saved', resp.data);
            swal({
              title: 'Success', type: 'success', text: resp.msg, onClose: () => {
                $('#txtDate').focus()
              }, timer: 1500
            });
            self.clear();
          } else {
            swal({
              title: 'Oops', text: resp.msg, type: 'error', onClose: () => {
                $('#txtDate').focus()
              }, timer: 1500
            });
          }
        }).catch(function (err) {
          swal({
            title: 'Oops', text: err.toString(), type: 'error', onClose: () => {
              $('#txtDate').focus()
            }, timer: 1500
          });
        }).finally(function () {
          $('#cardwindow').unblock();
        });
      }catch (e) {
        alert(e);
      }
    },

  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
